import { graphql } from "gatsby";
import React from "react";
import ArticleFeed from "../components/articlefeed";
import RelatedContent from "../components/relatedcontent";

import Layout from "../components/layout";
import SEO from "../components/seo";

const data = require("../images/data.jpeg");

const DataNews = ({ data }: any) => {
  return (
    <Layout>
      <SEO title="Data News" description="" lang="en" meta={[]} />
      {/* Banner Header */}
      <div
        className="container banner center white"
        style={{ backgroundImage: `url(${data})` }}
      >
        <h1>Data</h1>
      </div>

      {/* News Section */}
      <div className="container">
        <h2>Latest News</h2>
        <div className="row">
          <ArticleFeed data={data} />
          <div className="col-lg-4">
            <RelatedContent />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicArticle(filter: { tags: { in: "Data" } }) {
      edges {
        node {
          tags
          uid
          data {
            date
            header_image {
              url
            }
            header_image_caption {
              text
            }
            title {
              text
            }
            author {
              text
            }
          }
        }
      }
    }
  }
`;

export default DataNews;
